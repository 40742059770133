import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchDataStart, fetchDataSuccess, fetchDataFailure, updatePolicies } from '../accounts/configSlice'

export const accountsConfigApi = createApi({
  reducerPath: 'accountsConfigApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${window.location.origin}/auth/access/` }),
  tagTypes: ['Policies'],
  endpoints: build => ({
    getUserConfigByUserId: build.query({
      query: ({ organizationId, userId, token }) => ({
        url: `organization/${organizationId}/entity/?filter={"id":"${userId}"}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'user' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'user', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'user', error: error.toString() }))
        }
      }
    }),
    putPolicies: build.mutation({
      query: ({ userId, organizationId, policy, token }) => ({
        url: `organization/${organizationId}/entity/${userId}`,
        method: 'PUT',
        body: policy,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'user', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(updatePolicies({ key: 'user', payload: data })) // here it does NOT return array of 1 for one user so no need for data[0]
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'user', error }))
        }
      }
    }),
    getPolicies: build.query({
      query: ({ organizationId, token }) => ({
        url: `organization/${organizationId}/parameter?filter={"entity_type":"user"}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      providesTags: () => [{ type: 'UserPolicies' }], // tags the result of this query for cache invalidation and re-fetching data
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'userPolicies', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'userPolicies', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'userPolicies', error }))
        }
      }
    }),
    getAccountPolicies: build.query({
      query: ({ organizationId, token }) => ({
        url: `organization/${organizationId}/parameter?filter={"entity_type": "account"}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      providesTags: () => [{ type: 'AccountPolicies' }], // tags the result of this query for cache invalidation and re-fetching data
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'accountPolicies', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'accountPolicies', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'accountPolicies', error }))
        }
      }
    }),
    getAccountConfiguration: build.query({
      query: ({ accountsIds, organizationId, token }) => ({
        url: `organization/${organizationId}/?filter={"id": [${accountsIds.map(id => `"${id}"`).join(',')}]}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      providesTags: () => [{ type: 'UserAccounts' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'userAccounts', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'userAccounts', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'userAccounts', error }))
        }
      }
    }),
    getOrganizationConfigurationByOrgId: build.query({
      query: ({ organizationId, token }) => ({
        url: `organization/${organizationId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      providesTags: () => [{ type: 'organizations' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'organizations', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'organizations', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'organizations', error }))
        }
      }
    })
  })
})

export const {
  useGetUserConfigByUserIdQuery,
  useGetPoliciesQuery,
  useGetAccountPoliciesQuery,
  usePutPoliciesMutation,
  useGetAccountConfigurationQuery,
  useGetOrganizationConfigurationByOrgIdQuery
} = accountsConfigApi
// Hook names: https://redux-toolkit.js.org/rtk-query/usage/queries#hook-types
