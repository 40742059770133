import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchDataStart, fetchDataSuccess, fetchDataFailure } from '../accounts/accountsSlice'

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${window.location.origin}/auth` }),
  endpoints: build => ({
    getAccountsByUserId: build.query({
      query: ({ token, organizationId }) => ({
        url: `access/organization/${organizationId}/account`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'userAccounts' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'userAccounts', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'userAccounts', error: error.toString() }))
        }
      }
    })
  })
})

// Export hooks based on the defined endpoints
export const { useGetAccountsByUserIdQuery } = accountsApi
// Hook names: https://redux-toolkit.js.org/rtk-query/usage/queries#hook-types
