import themeColors from '@layers-frontend/commons/styles/themeColors'
import { NO_VALUE } from '@layers-frontend/commons/constants'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import StyledSelect from '../UIComponents/StyledSelect'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { StyledTooltip } from '../UIComponents/StyledTooltip'
import isNil from 'lodash/isNil'
import StyledSwitch from '../UIComponents/StyledSwitch/StyledSwitch'
import { TextButton } from '../UIComponents/StyledButtons'
import { Button } from '../UIComponents/buttons'
import { Chip } from '@mui/material'
import { styled as MuiStyled } from '@mui/material/styles'
import { FormattedLegend } from './GlobalLayersLegends/FormattedLegend'
import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

const StyledChip = MuiStyled(Chip)(() => ({
  backgroundColor: '#d9d9d9',
  color: themeColors.darkBlue,
  height: '24px',
  marginBottom: '4px',
  ':hover': {
    backgroundColor: '#b8b8b8'
  },
  '&>.MuiSvgIcon-root': {
    backgroundColor: 'transparent',
    color: themeColors.darkBlue,
    ':hover': {
      color: themeColors.blueDisabled
    }
  }
}))

const GlobalLayersContainer = styled.div`
  z-index: 1;
  bottom: 10px;
  right: 16px;
  display: flex;
  position: fixed;
  align-items: end;
`
const FadeInAnimation = keyframes`
  0% {opacity: 0}
  100% {opacity: 1}
`

const LegendContainer = styled.div`
  display: flex;
  background-color: ${themeColors.blueColor};
  opacity: 0.9;
  border-radius: 5px;
  color: white;
  padding: 5px 10px 15px 10px;
  flex-direction: column;
  margin-right: -55px;
  margin-bottom: 65px;
  min-width: 250px;
  animation: 0.3s ease-in-out both running ${FadeInAnimation};
`

const ButtonContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  font-size: 24px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  background-color: ${props => (props.isActive ? themeColors.vomitColor : themeColors.blueColor)};
  transition: all 0.3s ease-in-out;
`

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  padding-right: 10px;
  max-height: 440px;
`

const LegendButton = styled(Button)`
  background: ${themeColors.transparent};
  border: 1px solid ${themeColors.vomitColor};
  min-height: 2.5rem;
  color: ${themeColors.whiteColor};
  padding: 2px 10px;
  box-shadow: none;
  border-radius: 10px;
  font-size: 1.2rem;
  align-self: center;
  width: auto;
  &:hover {
    background: ${themeColors.vomitLightHover};
  }
`

const ChipContainer = styled.div`
  margin-bottom: 4px;
`

const CrossFilters = props => {
  const { t, selectGlobalLayer, resetGlobalLegend, touchedLegends } = props
  const handleClick = layer => {
    const baseName = get(layer, 'baseName')
    selectGlobalLayer(baseName)
  }
  const handleReset = layer => {
    resetGlobalLegend(layer)
  }
  return (
    <ChipContainer>
      <p>{t('Applied filters')}:</p>
      {touchedLegends.map(layer => {
        const isScaleFromDB = layer.isScaleFromDB
        const isScaleTypeCategoric = layer.isScaleTypeCategoric
        let touchedValues = ''
        if (isScaleFromDB || isScaleTypeCategoric) {
          touchedValues = layer.legend.reduce((current, legend) => {
            const visibility = legend.visible
            if (visibility) return current
            if (isScaleFromDB) {
              const value = legend.name
              const range = legend.range
              const text = range === NO_VALUE ? t(value) : `${range[0]} - ${range[1]}`
              if (current === '') return text
              return `${current}, ${text}`
            }
            const value = legend.value
            const text = value === null ? t('no data') : value
            if (current === '') return text
            return `${current}, ${text}`
          }, '')
        } else if (!layer.legend.showNulls) {
          touchedValues = layer.legend.value ? `${t('no data')}, ${layer.legend.value[0]} - ${layer.legend.value[1]}` : t('no data')
        }
        return (
          <StyledTooltip title={touchedValues} arrow={true} id={layer.baseName}>
            <StyledChip size="small" label={t(layer.baseName)} onClick={() => handleClick(layer)} onDelete={() => handleReset(layer)} />
          </StyledTooltip>
        )
      })}

      <TextButton
        onClick={() => {
          touchedLegends.map(layer => handleReset(layer))
        }}
        label={t('Clear all')}
        background={themeColors.blueColor}
        color={themeColors.whiteColor}
      />
    </ChipContainer>
  )
}

const GlobalLayersButton = ({
  isSearchView,
  isGlobalLayerLegendOpen,
  toggleGlobalLayerLegend,
  t,
  legends,
  selectedLayer,
  selectGlobalLayer,
  toggleLegend,
  updateSequentialLegend,
  toggleSequentialLegendNulls,
  setHighlightedCategory,
  toggleGlobalMapSelection,
  toggleGlobalLayersVisibility,
  toggleKeepSelection,
  keepSelection,
  touchedLegends,
  resetGlobalLegend
}) => {
  const handleLayerChange = useCallback(
    id => {
      const selected = find(legends, { id })
      const baseName = get(selected, 'baseName')
      trackEvent(TRACK_EVENTS.VIEWER_GLOBAL_MAP_SELECTED, { layer: baseName })

      selectGlobalLayer(baseName)
    },
    [legends, selectGlobalLayer]
  )
  const [isSelectionReset, setSelectionReset] = useState(true)
  const [isAddButtonShown, setShowAddButton] = useState(true) // display the Apply Selection button for the selected fields
  const [currentLegend, setCurrentLegend] = useState()

  useLayoutEffect(() => {
    if (selectedLayer.legend) {
      if (selectedLayer.legend.length === 0) {
        setShowAddButton(false)
      } else {
        if (selectedLayer.legend.length > 0 || !isNil(selectedLayer.legend.min) || !isNil(selectedLayer.legend.max)) {
          setShowAddButton(true)
        } else {
          setShowAddButton(false)
        }
      }
    }
  }, [selectedLayer])

  const handleShowHideAllClick = useCallback(() => {
    setSelectionReset(!isSelectionReset)
    toggleGlobalLayersVisibility(!isSelectionReset)
  }, [isSelectionReset, toggleGlobalLayersVisibility])

  const handleLegendElementClick = useCallback(
    index => {
      toggleLegend(selectedLayer.legend[index])

      if (selectedLayer.legend[index].visible) {
        setSelectionReset(false)
      }
    },
    [selectedLayer.legend, toggleLegend]
  )

  const handleLegendElementMouseLeave = useCallback(() => {
    setCurrentLegend(null)
    setHighlightedCategory(null)
  }, [setHighlightedCategory])

  const handleLegendElementMouseEnter = useCallback(
    (hoveredLegend, index) => {
      setCurrentLegend(hoveredLegend)

      if (selectedLayer.isScaleFromDB && hoveredLegend.id !== currentLegend?.id) {
        setHighlightedCategory(hoveredLegend)
      } else if (!selectedLayer.isScaleFromDB && hoveredLegend.value !== currentLegend?.value) {
        setHighlightedCategory(index === 0 ? 'null' : hoveredLegend.value)
      }
    },
    [currentLegend?.id, currentLegend?.value, selectedLayer.isScaleFromDB, setHighlightedCategory]
  )

  if (!isSearchView) return null
  const selectedLayerId = get(selectedLayer, 'id')
  const selectedLayerBaseName = get(selectedLayer, 'baseName')
  const scale = get(selectedLayer, 'scale')

  const emptyLegend =
    selectedLayer.isScaleFromDB || selectedLayer.isScaleTypeCategoric
      ? isEmpty(selectedLayer.legend)
      : isNil(selectedLayer.legend.min) && isNil(selectedLayer.legend.max)

  return (
    <GlobalLayersContainer className="toolbar">
      {isGlobalLayerLegendOpen ? (
        <LegendContainer>
          <StyledSelect
            menuProps={{
              PaperProps: {
                style: {
                  maxHeight: 440
                }
              }
            }}
            renderValue={() => t(selectedLayerBaseName)}
            options={legends}
            value={selectedLayerId}
            defaultValue=""
            onChange={id => {
              setSelectionReset(true)
              handleLayerChange(id)
            }}
          />
          <Legend>
            {selectedLayer.legend ? (
              <>
                <FormattedLegend
                  legend={selectedLayer.legend}
                  scale={scale}
                  selectedLayerBaseName={selectedLayerBaseName}
                  isScaleFromDB={selectedLayer.isScaleFromDB}
                  isScaleTypeCategoric={selectedLayer.isScaleTypeCategoric}
                  isSelectionReset={isSelectionReset}
                  onShowHideAllClick={handleShowHideAllClick}
                  onLegendElementClick={handleLegendElementClick}
                  onLegendElementMouseLeave={handleLegendElementMouseLeave}
                  onLegendElementMouseEnter={handleLegendElementMouseEnter}
                  translate={t}
                  toggleSequentialLegendNulls={toggleSequentialLegendNulls}
                  updateSequentialLegend={updateSequentialLegend}
                />
                {!emptyLegend ? (
                  <StyledSwitch
                    size={'small'}
                    label={t('Keep selection')}
                    checked={keepSelection}
                    onChange={event => {
                      toggleKeepSelection(event.target.checked)
                    }}
                  />
                ) : null}
                {keepSelection && !isEmpty(touchedLegends) ? (
                  <CrossFilters t={t} resetGlobalLegend={resetGlobalLegend} touchedLegends={touchedLegends} selectGlobalLayer={selectGlobalLayer} />
                ) : null}
                {isAddButtonShown && (
                  <LegendButton
                    onClick={() => {
                      toggleGlobalMapSelection(true)
                    }}
                  >
                    {t('Apply selection')}
                  </LegendButton>
                )}
              </>
            ) : null}
          </Legend>
        </LegendContainer>
      ) : null}
      <ButtonContainer onClick={toggleGlobalLayerLegend} isActive={isGlobalLayerLegendOpen}>
        <StyledTooltip title={isGlobalLayerLegendOpen ? t('Hide Global Layer') : t('Show Global Layer')} placement="top-end">
          <i
            className="fa-solid fa-layer-group"
            style={{ padding: '4px', color: isGlobalLayerLegendOpen ? themeColors.blueColor : themeColors.vomitColor, transition: 'all 0.3s ease-in-out' }}
          />
        </StyledTooltip>
      </ButtonContainer>
    </GlobalLayersContainer>
  )
}

export default GlobalLayersButton
